<template>
<div class="tw-flex tw-w-full tw-flex-col">
  <vue-feather class="tw-flex tw-mr-3 tw-cursor-pointer" style="width: 24px; height: 24px; color: #9CABB5"
               type="arrow-left" @click="back"></vue-feather>
  <h3 class="title tw-mt-5">{{jobTitle}}</h3>
  <small class="company tw-mt-2">{{company}}</small>
</div>
</template>

<script>
export default {
  name: "JobDetailDescription",
  props:{
    jobTitle : [String],
    company : [String]
  },
  methods:{
    back(){
      this.$router.push({name: 'AppliedJobs'})
    }
  }
}
</script>

<style scoped lang="scss">
.title{
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: -0.02em;
  color: #1E323F;
  text-transform: capitalize;
}
.company{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
  text-transform: capitalize;
}
</style>
