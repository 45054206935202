<template>
<applicant-dashboard-layout>
  <template #header>
    <Header>
      <template #route-back-component>
        <div class="tw-flex tw-items-center">
          <vue-feather class="tw-flex tw-mr-3" style="width: 24px; height: 24px;" type="arrow-left" @click="$router.push({name: 'AppliedJobs'})"></vue-feather>
          <label class="title">{{$route.query.job_title}}</label>
        </div>
      </template>
      <template #right-header-component>
        <ProfileDropDown/>
      </template>
    </Header>
  </template>
  <template #dashboard-sideNav>
    <job-detail-description :company="$route.query.company_name" :job-title="$route.query.job_title" />
  </template>
  <template #dashboard-content>
    <Loader v-if="loading" :loading="loading"/>
    <div v-if="!loading" class="tw-flex tw-w-full lg:tw-w-3/5 tw-flex-col">
      <div class="tw-flex tw-w-full  tw-justify-between">
        <h6 class="applied-title">Applied</h6>
        <label class="applied-date">{{processDay(stages.application_created)}}</label>
      </div>
      <div  v-if="stages.application_status === 'Not Shortlisted'">
        <div class="tw-flex tw-w-full  tw-justify-between tw-mt-10">
          <label class="red-label tw-py-1 tw-px-2">Not Shortlisted</label>
          <vue-feather type="slash" style="height: 20px; width: 20px; color: #FF2E2E"></vue-feather>
        </div>
        <v-divider class="tw-mt-3"/>
      </div>

      <div v-if="stages.application_status === 'Shortlisted'">
        <div class="tw-flex tw-w-full  tw-justify-between tw-mt-10">
          <label class="green-label tw-py-1 tw-px-2">Shortlisted</label>
          <vue-feather type="check" style="height: 20px; width: 20px; color: #0BCA7A"></vue-feather>
        </div>
        <v-divider class="tw-mt-3"/>
      </div>
      <div v-if="stages.application_stages && stages.application_stages.length > 0">
        <div class="tw-w-full tw-mt-7 tw-flex-col" v-for="(item, index) in stages.application_stages" :key="index">
          <div @click="goToAssessment"  :style="[item.status === 'completed'&& is_invited.is_invited_external_test ? {pointerEvents:'none',cursor:'not-allowed'}: {cursor:'pointer'}]" v-if="item.stage === 'screening_test'" >
            <div class="tw-flex tw-w-full  tw-justify-between">
              <h6 class="applied-title" :class="item.stage === 'pending' ?'tw-cursor-pointer': ''">{{ item.title }}</h6>
              <label class="applied-date">{{processDay(item.created)}} </label>
            </div>
            <div class="mt-4">
              <span class="due-date">Due {{formatDate(item.due_date)}}</span>
            </div>
            <div class="tw-flex tw-w-full  tw-justify-between mt-3">
              <div style="background: #F7FCFF; border-radius: 4px;" class="py-1 px-2">
                <span v-if="statusText(item.status)==='completed' && !is_invited.is_invited_external_test" class="status text-uppercase"></span>

                <span v-else class="status text-uppercase">{{statusText(item.status)}}</span>
              </div>
              <vue-feather v-if="item.status === 'pending'" type="arrow-right" style="color: #D0DCE4"/>
              <vue-feather v-if="item.status === 'in_progress'" type="slash" style="height: 20px; width: 20px; color: #FF2E2E"></vue-feather>
              <vue-feather v-if="item.status === 'completed'" type="check" style="height: 20px; width: 20px; color: #0BCA7A"></vue-feather>
            </div>
            <v-divider class="tw-mt-3"/>
          </div>

          <div :class="[item.status === 'pending' ? 'tw-cursor-pointer':'']" v-if="item.stage === 'interview'" @click="getInterviewDetail(item.activity_id,item.status)">
            <div class="tw-flex tw-w-full  tw-justify-between">
              <h6 class="applied-title">{{ item.title }}</h6>
              <label class="applied-date">{{processDay(item.created)}}</label>
            </div>
            <div class="mt-4">
              <span class="due-date">{{formatDate(item.due_date)}}&nbsp;·&nbsp;{{formatTime(item.due_date)}}</span>
            </div>
            <div class="tw-flex tw-w-full  tw-justify-between mt-3">
              <div style="background: #F7FCFF; border-radius: 4px;" class="py-1 px-2">
                <span class="status text-uppercase">{{statusText(item.status)}}</span>
              </div>
              <div>
                <Loader v-if="interviewLoader" :loading="interviewLoader"/>
                <div v-if="!interviewLoader">
                  <vue-feather v-if="item.status === 'pending'"
                               type="arrow-right" style="color: #D0DCE4" />
                  <vue-feather v-if="item.status === 'in_progress'" type="slash" style="height: 20px; width: 20px; color: #FF2E2E"></vue-feather>
                  <vue-feather v-if="item.status === 'accepted'" type="check" style="height: 20px; width: 20px; color: #0BCA7A"></vue-feather>
                </div>
              </div>
            </div>
            <v-divider class="tw-mt-3"/>
          </div>
          <div :class="[item.status === 'pending' ? 'tw-cursor-pointer':'']" v-if="item.stage === 'offer'"
               @click="$router.push({name:'OfferDetailsState',params:{'job_id':item.activity_id}})">
            <div class="tw-flex tw-w-full  tw-justify-between">
              <h6 class="applied-title">{{ item.title }}</h6>
              <label class="applied-date">{{processDay(item.created)}} </label>
            </div>
            <div class="mt-4">
              <span class="due-date">{{item.company_name}} recruitment team</span>
            </div>
            <div class="tw-flex tw-w-full  tw-justify-between mt-3">
              <div style="background: #F7FCFF; border-radius: 4px;" class="py-1 px-2">
                <span class="status text-uppercase">{{statusText(item.status)}}</span>
              </div>
              <vue-feather v-if="item.status === 'pending'" type="arrow-right" style="color: #D0DCE4"/>
              <vue-feather v-if="item.status === 'in_progress'" type="slash" style="height: 20px; width: 20px; color: #FF2E2E"></vue-feather>
              <vue-feather v-if="item.status === 'completed'" type="check" style="height: 20px; width: 20px; color: #0BCA7A"></vue-feather>
            </div>
            <v-divider class="tw-mt-3"/>
          </div>
      </div>
      </div>
      <div>
        <!-- Accept Job Offer-->

        <modal :loading="accept" :dialog="showDialog" title="Your Interview Schedule"
               cancel-text="Decline" ok-text="Accept" @close="close" @cancel="showDecline" @ok="acceptInterview">
          <template #content>
            <div class="tw-flex tw-w-full tw-flex-col tw-mt-5 tw-p-5" style="background: #FBFBFB">
              <label class="title-bold">{{ interviewDetails.invited_by }} is inviting you to an interview</label>
              <div class="tw-flex tw-w-full tw-mt-5 tw-flex-row">
                <div class="tw-flex tw-w-3/5 tw-flex-col">
                  <label class="desc ">Date</label>
                  <p class="title-bold tw-mt-5">{{ formatDate(interviewDetails.date) }}</p>
                </div>
                <div class="tw-flex tw-w-2/5 tw-flex-col">
                  <label class="desc ">Time</label>
                  <p class="title-bold tw-mt-5">{{ getTime}}</p>
                </div>
              </div>
              <div class="tw-flex tw-w-full tw-flex-col">
                <label class="desc text-capitalize">{{ interviewDetails.type }}</label>
                <p class="title-bold tw-mt-5">{{ interviewDetails.detail }}</p>
              </div>
            </div>
          </template>
        </modal>

        <!-- Decline Job Offer-->
        <modal :loading="decline" :dialog="showDeclineDialog" title="Reason for Declining"
               cancel-text="Back" ok-text="Decline" @close="closeDecline" @cancel="cancelDecline" @ok="declineInterview">
          <template #content>
            <div class="tw-flex tw-w-full tw-flex-col tw-pt-5">
              <v-select v-model="reason" :items="reasonsForDeclining" solo hide-details placeholder="Select a reason" />
              <label class="desc tw-mt-8 tw-mb-2">Specify Reason</label>
              <v-textarea v-model="specify" class="" solo hide-details />
            </div>
          </template>
        </modal>
      </div>

    </div>
  </template>
</applicant-dashboard-layout>
</template>

<script>
import ApplicantDashboardLayout from "../../layout/ApplicantDashboardLayout";
import Header from "../../reusables/Header";
import ProfileDropDown from "../../reusables/ProfileDropDown";
import JobDetailDescription from "../jobs/JobDetailDescription";
import Modal from "../../reusables/Modal";
import {getApplicantStage, getInterviewDetail, acceptInterviewOffer, declineInterviewOffer} from "../../../services/api/APIService";
import moment from "moment";
import Loader from "@/components/reusables/Loader";
import timeDay from "@/mixins/timeDay.mixin"
import {temporaryScoreApplicant} from "@/services/api/APIService";
import {mapGetters} from "vuex";
export default {
  name: "AppliedJobDetails",
  components: {Loader, Modal, JobDetailDescription, ProfileDropDown, Header, ApplicantDashboardLayout},
  mixins:[timeDay],
  data(){
    return{
      reasonsForDeclining:["I won’t be available", "I got another offer", "I’m no longer interested"],
      showDialog : false,
      showDeclineDialog : false,
      stages:{},
      reason: '',
      specify: '',
      interviewDetails: {},
      accept: false,
      decline: false,
      interview_id: null,
      loading: false,
      interviewLoader : false,
    }
  },
  // mounted(){
  //   let data = {}
  //   data.job_id = this.$route.params.job_id
  //   data.external_test_id = this.stages.external_test_id
  //   try {
  //     this.$store.dispatch('checkInvitedCandidate', data)
  //   } catch (e) {
  //     console.log(e)
  //   }
  // },
  methods:{
    close(){
      this.showDialog= false
    },
    showDecline() {
      this.showDeclineDialog = true
    },
    closeDecline(){
      this.showDeclineDialog = false
    },
    cancelDecline(){
      this.showDeclineDialog = false
      this.showDialog = true
    },
    formatDate(item){
      return moment(item).format('ll');
    },
    statusText(name) {
      return name.replace(/_/g,' ')
    },

    // formatTime(item){
    //   return moment(item).format('LT')
    // },
    makeCall(item) {
      if(item.stage === 'interview') {
        this.interview_id = item.activity_id
        this.getInterviewDetail()
      }
    },
    async getJobStages(){
      this.loading = true
      const  userData = JSON.parse(sessionStorage.getItem('userData'))
      const data = {}
      data.job_id = this.$route.params.job_id
      data.candidate_email = userData.email
      await getApplicantStage(data).then(async res => {
        this.stages = res.data
        this.loading = false
        console.log(res.data, "getting states")
        await this.$store.dispatch('checkInvitedCandidate', {
          job_id: this.$route.params.job_id,
          external_test_id: res.data.external_test_id
        })

      })
          .catch(err=> {
            this.loading = false
            this.$displaySnackbar({
              message: err.response.data.message,
              success: false
            })
          })
    },

    async getInterviewDetail(interviewId,status){
      if (status === 'pending'){
        this.interviewLoader = true
        this.interview_id = interviewId
        const  userData = JSON.parse(sessionStorage.getItem('userData'))
        if (userData){
          let data ={}
          data.job_id = interviewId
          data.candidate_email = userData.email

          await getInterviewDetail(data).then(
              res =>{
                this.interviewDetails = res.data
                this.interviewLoader = false
                this.showDialog = true

              })
              .catch(err=>{
                this.showDialog = false
                this.interviewLoader = false
                let errorKey = Object.keys(err.response.data)
                this.$displaySnackbar({
                  message: err.response.data[errorKey[0]],
                  success: false
                })
              })
        }
      }
    },
    acceptInterview() {
      const  userData = JSON.parse(sessionStorage.getItem('userData'))
      this.accept = true
      const data = {
        email: userData.email,
        interview_id: this.interview_id.toString(),
        job_id: this.$route.params.job_id.toString()
      }
      acceptInterviewOffer(data).then(
          res => {
            this.accept = false
            this.getJobStages()
            this.$displaySnackbar({
              message: res.data.detail,
              success: true
            })
            this.close()
          },
          error => {
            this.accept = false
            let errorKey = Object.keys(error.response.data)
            this.$displaySnackbar({
              message: error.response.data[errorKey[0]],
              success: false
            })
          }
      )
    },
    declineInterview() {
      this.decline = true
      const data = {
        interview_id: this.interview_id,
        job_id: this.$route.params.job_id,
        reason: this.reason.concat(' ' + this.specify)
      }
      declineInterviewOffer(data).then(
          res => {
            this.decline = false
            this.getJobStages()
            this.$displaySnackbar({
              message: res.data.detail,
              success: true
            })
            this.close()
            this.closeDecline()
          },
          error => {
            this.decline = false
            let errorKey = Object.keys(error.response.data)
            this.$displaySnackbar({
              message: error.response.data[errorKey[0]],
              success: false
            })
          }
      )
    },

    goToAssessment(){
      this.$router.push({name:'AssessmentTypeView',query:{id:this.$route.params.job_id,company_name:this.$route.query.company_name,job_title:this.$route.query.job_title}})
    },
    scoreApplicantTemporary(){
      temporaryScoreApplicant(this.$route.params.job_id)
      .then(res=>{
        console.log(res)
      })
      .catch(err=>{
        console.log(err)
      })
    }
  },
  computed:{
    ...mapGetters(['is_invited']),

    formatTime(){
      return function(item){
        return moment(item.split(" ")[1], "HH:mm:ss").format("hh:mm A");

      }
    },
    getTime(){
      return moment(this.interviewDetails.time, "HH:mm:ss").format("hh:mm A");
    }
  },
  created() {
    this.getJobStages()
    // this.scoreApplicantTemporary()
  }

}
</script>

<style scoped lang="scss">
.title{
  font-family: IBM Plex Serif, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: -0.02em;
  color: #1E323F;
}
.applied-title{
  font-family: DM Sans, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #1E323F;
}
.applied-date{
  font-family: DM Sans, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.01em;
  color: #9CABB5;
}
.red-label{
  font-family: DM Sans, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  text-align: justify;
  letter-spacing: 0.01em;
  background: #FFF0F0;
  border-radius: 4px;
  color: #CB0303;
  text-transform: uppercase;
}
.green-label{
  font-family: DM Sans, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  text-align: justify;
  letter-spacing: 0.01em;
  color: #076F56;
  background: #F0FFF7;
  border-radius: 4px;
  text-transform: uppercase;
}
.due-date {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;

  color: #475661;
}
.status {
  ont-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  text-align: justify;
  letter-spacing: 0.01em;
  color: #07556F;

}
.curs{
  cursor: pointer;
}
</style>
