<template>
  <v-dialog
      v-model="dialog"
      max-width="600"
      transition="scale-transition"
      origin="center center"

      persistent>
      <div class="tw-flex tw-w-full lg:tw-p-10 tw-p-5 tw-flex-col"
           style="background-color: #FFFFFF;border-radius: 10px;">
        <div class="tw-flex tw-w-full tw-justify-between">
          <label class="title">{{title}}</label>
          <vue-feather class="tw-cursor-pointer" type="x" @click="$emit('close')" />
        </div>
        <slot name="content">

        </slot>
        <div class="tw-flex tw-w-full tw-justify-between lg:tw-justify-end tw-items-center tw-mt-10">
          <base-button class="tw-mr-5" @click="$emit('cancel')" outlined :button-text="cancelText" />
          <base-button @click="$emit('ok')" :button-text="okText" :loading="loading"/>

        </div>
      </div>
</v-dialog>
</template>

<script>
import BaseButton from "./BaseButton";
export default {
  name: "Modal",
  components: {BaseButton},
  props:{
    dialog : [Boolean],
    loading : [Boolean],
    title : [String],
    cancelText : [String],
    okText : [String]
  }
}
</script>

<style scoped lang="scss">
.title{
  font-family: IBM Plex Serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 24px !important;
  line-height: 31px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em !important;
  color: #1E323F;
}
</style>